<template>
    <nav class="quicklinks-list">
        <h3 class="quicklink-list__title">
            <slot name="title">{{ title }}</slot>
        </h3>

        <template v-for="(link, index) in links">
            <slot :link="link">
                <BaseQuicklink
                    :key="index"
                    :uri="link.uri"
                >
                    {{ link.text }}
                </BaseQuicklink>
            </slot>
        </template>
    </nav>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        links: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>

<style lang="less">
.quicklink-list__title {
    @media @q-lg-min {
        margin-bottom: 1rem;
    }
}
</style>
